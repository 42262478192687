import './Footer.css';

function Footer(props) {
  return (
    // <footer className="Footer" style={{"backgroundColor": props.backgroundColor}}>
    <footer className="Footer">
      <p className="disclaimer">Copyright 2025 Eve Juneau</p>
    </footer>
  );
}

export default Footer;
